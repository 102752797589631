import React from 'react';
import { styled } from '@mui/material/styles';
import { Badge, Link, Paper, Typography } from '@mui/material';

const PaperWrapper = styled(Paper)<{ component?: React.ElementType }>({
  borderRadius: '10px',
  marginBottom: 20,
  maxWidth: '80%',
  paddingTop: 20,
  paddingBottom: 10,
  marginLeft: 'auto',
  marginRight: 'auto'
});

const StyledTypography = styled(Typography)<{ component?: React.ElementType }>({
  margin: 10,
  padding: 10,
  color: '#fff'
});

interface Site {
  siteName: string,
  siteDescription: string,
  level: string,
  url: string
}

function Project(props: Site) {

  let { siteName, siteDescription, level, url } = props;

  return (
    <PaperWrapper>
      <Link variant="h4" underline="always" color="#fff" href={url} target="_blank" rel="noopener noreferrer"
      >{siteName}</Link>
      <br />
      <br />
      <Badge badgeContent={level} color="secondary">
        <div></div>
      </Badge>
      <StyledTypography variant="body1">{siteDescription}</StyledTypography>
    </PaperWrapper>
  )
}

export default Project;