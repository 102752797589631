import React from 'react';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import EmailIcon from '@mui/icons-material/Email';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
// import HelpCenterIcon from '@mui/icons-material/HelpCenter';

const LightTooltip = styled(({className, ...props}: TooltipProps) => (
  <Tooltip {...props}  classes={{ popper: className }} followCursor />
))(({ theme }) => ({
  [`&.${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.body.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}));

const ToolbarWrapper = styled(Toolbar)<{ component?: React.ElementType }>(
  ({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    position: 'fixed',
    bottom: 0,
    justifyContent: 'center'
}));

const ToolbarMargin = styled('div')<{ component?: React.ElementType }>(
  ({ theme }) => ({
    ...theme.mixins.toolbar
}))

const StyledContactInfo = styled(Typography)<{ component?: React.ElementType }>(
  ({ theme }) => ({
    color: theme.palette.body.white,
    paddingTop: '2%',
    display: 'inline'
}))

const StyledLink = styled('a')<{ component?: React.ElementType }>(
  ({ theme }) => ({
    color: theme.palette.body.white
}))

function Footer() {
  return (
    <div>
      <ToolbarMargin />
      <AppBar>
        <ToolbarWrapper>
          <span style={{ minWidth: '53px' }}>
            <StyledContactInfo>
              <StyledLink href='mailto:christopher@redwolfprojects.com' target="_blank">
                <LightTooltip title="Email Red Wolf Projects">
                  <EmailIcon />
                </LightTooltip>
              </StyledLink>
            </StyledContactInfo>
            {/*<StyledContactInfo>*/}
            {/*  <StyledLink href="https://rwp.atlassian.net/servicedesk/customer/portal/4" target="_blank">*/}
            {/*    <LightTooltip title="Submit a support ticket in JIRA">*/}
            {/*      <HelpCenterIcon />*/}
            {/*    </LightTooltip>*/}
            {/*  </StyledLink>*/}
            {/*</StyledContactInfo>*/}
          </span>
        </ToolbarWrapper>
      </AppBar>
    </div>
  )
}

export default Footer;
