import { useState, useEffect } from 'react';
import './App.css';
import {
  HashRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import { ThemeProvider } from '@mui/system';

import Home from './pages/Home';
import { theme, DarkTheme } from './styles/Theme';
import Navbar from './components/Navbar';
import About from './pages/About';
import Projects from './pages/Projects';
import Footer from './components/Footer';


function App() {
  const [light, setLight] = useState(true);//props.light && props.light === 'false' ? false : true)

  const onChange = () => {
    localStorage.setItem("theme", !light ? 'true' : 'false');
    setLight(!light);
  }

  let appliedTheme = light ? theme : DarkTheme;

  useEffect(() => {
    if(!light) {
      document.getElementById('body')!.style.backgroundColor = '#424242';
    } else {
      document.getElementById('body')!.style.backgroundColor = '#fff';
    }
  }, [light])

  return (
    <ThemeProvider theme={ appliedTheme }>
      <Router>
        <Navbar onChange={ onChange } light={ light } />
        <Routes>
          <Route path='/' element={<Home />}/>
          <Route path='/about' element={<About />}/>
          <Route path='/projects' element={<Projects />} />
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
