import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/wolflogo_white.png';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { Tabs, Tab, Switch, FormControlLabel } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import MenuIcon from '@mui/icons-material/Menu';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';


declare module '@mui/material/styles' {
  interface Theme {
    palette: {
      body: {
        backgroundColor: string;
        color: string;
        white: string;
        hov: string;
      },
      primary: {
        main: string
      }
      typography: {
        tab: {
          fontFamily: string;
          textTransform: string;
          fontWeight: string;
          fontSize: string;
        }
      }
    }
  }
}

interface TabWrapperProps {
  component?: React.ElementType;
  to?: string;
  label?: string
}


const ToolbarWrapper = styled(Toolbar)<{ component?: React.ElementType }>(
  ({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    justifyContent: 'space-between'
}))

const ToolbarMargin = styled('div')<{ component?: React.ElementType }>(
  ({ theme }) => ({
    ...theme.mixins.toolbar
}))

const TabsWrapper = styled(Tabs)<{ component?: React.ElementType }>({
    marginLeft: 'auto'
})

const TabWrapper = styled((props: TabWrapperProps) => (
  <Tab {...props}/>))(({ theme }) => ({
    minWidth: 10,
    marginLeft: '25px',
    color: theme.palette.body.white,
    '&:hover': {
      color: theme.palette.body.hov
    },
    '&.Mui-selected': {
      color: theme.palette.body.white
    },
    fontFamily: "Roboto, Helvetica, Arial, serif",
    textTransform: 'none',
    fontWeight: '700',
    fontSize: '1rem'
}))

const FormControlLabelWrapper = styled(FormControlLabel)<{ component?: React.ElementType }>(
  ({ theme }) => ({
    color: '#ffffff',
    marginLeft: '25px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0
    }
}))

const LinkWrapper = styled(Link)<{ component?: React.ElementType}>(
  ({ theme }) => ({
    fontFamily: 'Roboto',
    textTransform: 'none',
    fontWeight: '700',
    fontSize: '1rem',
    color: theme.palette.body.white
}))

function ElevationScroll(props: { children: any; }) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  });

  return React.cloneElement(children, {
    elevation: trigger ? 9 : 0
  });
}

function Navbar(props: any) {
  const [value, setValue] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);

  //Function to set the document title based on which tab is currently selected
  const handleChange = (event: any, newValue: number) => {
    if (newValue === 0 && event) {
      document.title = 'Red Wolf Projects - Home';
    } else if (newValue === 1) {
      document.title = 'Red Wolf Projects - Services';
    } else if (newValue === 2) {
      document.title = 'Red Wolf Projects - About Us';
    }
    setValue(newValue);
  }

  let handleClick = () => {
    window.location.href = '/';
    document.title = 'Red Wolf Projects - Home';
  }

  useEffect(() => {
    if (window.location.hash === '#/' && value !== 0) {
      setValue(0);
    } else if (window.location.hash === '#/about' && value !== 1) {
      document.title = 'Red Wolf Projects - About Us';
      setValue(1);
    } else if (window.location.hash === '#/projects' && value !== 2) {
      document.title = 'Red Wolf Projects - Projects'
      setValue(2);
    }
  }, [value]);

  const theme = useTheme();
  const sMatches = useMediaQuery(theme.breakpoints.down('sm'));

  const tabs = (
    <React.Fragment>
      <TabsWrapper value={ value } onChange={ handleChange } indicatorColor='primary'>
        <TabWrapper component={ Link } to='/' label='Home' />
        <TabWrapper component={ Link } to='/about' label='About Us' />
        <TabWrapper component={ Link } to='/projects' label='Projects' />
      </TabsWrapper>
      <FormControlLabelWrapper
          control={ <Switch checked={ !props.light } onChange={ props.onChange } /> }
          label='Dark Mode'
        />
    </React.Fragment>
  )

  const drawer = (
    <React.Fragment>
      <Button style={{ color: '#fff' }} onClick={() => setDrawerOpen(true)} startIcon={<MenuIcon />}></Button>
      <SwipeableDrawer
        onOpen={() => null} open={drawerOpen} anchor={'left'} onClose={() => setDrawerOpen(false)}>
        <List>
          <ListItem>
            <LinkWrapper
              onClick={() => setDrawerOpen(false)} to='/'>Home</LinkWrapper>
          </ListItem>
          {/* <ListItem>
            <LinkWrapper
              onClick={() => setDrawerOpen(false)} to='/services'>Services</LinkWrapper>
          </ListItem> */}
          <ListItem>
            <LinkWrapper
              onClick={() => setDrawerOpen(false)} to='/about'>About Us</LinkWrapper>
          </ListItem>
          <ListItem>
            <LinkWrapper
              onClick={() => setDrawerOpen(false)} to='/projects'>Projects
            </LinkWrapper>
          </ListItem>
          <ListItem>
            <FormControlLabelWrapper
              control={<Switch checked={!props.light}
                onChange={props.onChange} />}
              label={"Dark Mode"} />
          </ListItem>
        </List>
      </SwipeableDrawer>
    </React.Fragment>
  )

  return (
    <div>
      <ElevationScroll>
        <AppBar>
          <ToolbarWrapper>
            <Link to={'/'} >
              <img
                style={{ marginRight: '5px' }}
                height='40px'
                width='40px'
                src={logo}
                alt={'Logo'}
              />
            </Link>
            <Typography
              style={{ marginRight: '5px' }}
              onClick={handleClick}
              variant={sMatches ? 'h6' : 'h5'}>
              Red Wolf Projects
            </Typography>
            {sMatches ? drawer : tabs}
          </ToolbarWrapper>
        </AppBar>
      </ElevationScroll>
      <ToolbarMargin />
    </div>
  )
}

export default Navbar;