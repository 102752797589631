import React from 'react';
import { styled } from '@mui/material/styles';
import { List, ListItem, Typography } from '@mui/material';
import { HashLink as Link } from 'react-router-hash-link';
import logo from '../images/redwolf.png';

declare module '@mui/material/styles' {
  interface Theme {
    palette: {
      body: {
        backgroundColor: string;
        color: string;
        white: string;
        hov: string;
      },
      primary: {
        main: string
      }
      typography: {
        tab: {
          fontFamily: string,
          textTransform: string,
          fontWeight: string,
          fontSize: string
        }
      }
    }
  }
}

const StyledHeader = styled(Typography)<{ component?: React.ElementType }>(
  ({ theme }) => ({
    ...theme.typography.h1,
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.5em",
      marginBottom: "5%"
    }
}))

const DivWrapper = styled('div')<{ component?: React.ElementType }>(
({ theme }) => ({
  backgroundColor: theme.palette.body.backgroundColor,
  color: theme.palette.body.color,
  textAlign: 'center',
  paddingTop: 20,
  paddingLeft: '1em',
  paddingRight: '1em',
  marginBottom: '5%'
}))

const StyledListItem = styled(ListItem)<{ component?: React.ElementType }>({
  justifyContent: 'center'
})

const StyledLink = styled(Link)<{ component?: React.ElementType }>({
  color: 'inherit'
})

export default function Home(){
    return(
      <div id='home-main-div'>
        <DivWrapper>
          <StyledHeader variant='h1'>
            <img src={logo} style={{width: 50, height: 50, marginRight: 10, verticalAlign: 'text-top'}} alt={"Red Wolf Projects logo"}/>
            Software Development. Made Simple.
          </StyledHeader>
          <hr />
          <Typography variant='h4'>Our Services</Typography>
          <List>
            <StyledListItem>
              <StyledLink to='/#custom-website-development'>
                Custom Website Development
              </StyledLink>
            </StyledListItem>
            <StyledListItem>
            <StyledLink to='/#logo-design'>
              Logo Design
            </StyledLink>
          </StyledListItem>
          <StyledListItem>
            <StyledLink to='/#mobile-app-development'>
              iOS and Android App Development
            </StyledLink>
          </StyledListItem>
          <StyledListItem>
            <StyledLink to='/#custom-software-development'>
              Custom Software Development
            </StyledLink>
          </StyledListItem>
          </List>
        </DivWrapper>
        <DivWrapper>
        <Typography variant='h4'>Why Choose Red Wolf Projects?</Typography>
        <Typography variant='subtitle1'>At Red Wolf Projects, the goal is to help you succeed by building you a website or other software to help you stand out among your peers. Our goal is to give you software to simplify your daily tasks to allow you to focus on your business tasks that need your attention.</Typography>
      </DivWrapper>
      <DivWrapper id="custom-website-development">
        <Typography variant="h4">Custom Website Development</Typography>
        <Typography variant="subtitle1">
          We use the React library developed by Facebook paired with Material design to build beautiful, responsive websites. We can also manage or assist you in building a website using Wix or another template website builder depending upon your needs.
        </Typography>
      </DivWrapper>
      <DivWrapper id="logo-design">
        <Typography variant="h4">Logo Design</Typography>
        <Typography variant="subtitle1">
          If you need a beautifully designed logo, we can work with you to provide you with a logo that can be used on your website, business cards, and any gear you want to put your logo on.
        </Typography>
      </DivWrapper>
      <DivWrapper id='mobile-app-development'>
        <Typography variant="h4">iOS/Android App Development</Typography>
        <Typography variant="subtitle1">
          Extend Functionality. Extend Access. Increase Engagement.
        </Typography>
        <Typography variant="subtitle1">
          Integrate your web experience or create a standalone app with either mobile platform. We use React Native to write our mobile applications which run on both Android and iOS platforms with only one code base. This means less time writing code and less code to maintain while reaching more users.
        </Typography>
      </DivWrapper>
      <DivWrapper id="custom-software-development">
        <Typography variant='h4'>Custom Software Development</Typography>
        <Typography variant='subtitle1'>If you need a custom piece of software, we can build software to automate daily or monthly tasks to save you time and money. Contact us for a free consultation to see how we can help.</Typography>
      </DivWrapper>
      </div>
    )
}