import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

import Project from '../components/Project';

const DivWrapper = styled('div')<{ component?: React.ElementType}>(
  ({ theme }) => ({
    backgroundColor: theme.palette.body.backgroundColor,
    color: theme.palette.body.color,
    textAlign: 'center',
    paddingLeft: '1em',
    paddingRight: '1em',
    paddingTop: 20
}));

export default function Projects() {
  const projects = [
    // {
    //   siteName: "Lucent Reflections",
    //   siteDescription: "Lucent Reflections Automotive Detailing is a veteran owned and operated vehicle detailing service that specializes in paint correction and protection, ceramic coating, and PPF. If your vehicle needs some special attention, this is the place to go.",
    //   level: "PREMIUM",
    //   url: "https://lrautomotivedetailing.com"
    // },
    {
      siteName: "MD FOP Golf Classic",
      siteDescription: "Registration site for the annual FOP Lodge #102 Fall Charity Golf Classic.",
      level: "PREMIUM",
      url: "https://mdfop102golf.com"
    },
    // {
    //   siteName: "Double Doc Farm",
    //   siteDescription: "Double Doc Farm is a veterinarian owned pasture raised beef cow-calf farm.",
    //   level: "BASIC",
    //   url: "https://redwolfprojectsinf.wixsite.com/doubledocfarm"
    // },
    // {
    //   siteName: "Red Wolf Projects",
    //   siteDescription: "A basic version of our Red Wolf Projects Website. This one includes a contact form as well as a joke on every business day in case you need a laugh.",
    //   level: "BASIC",
    //   url: "https://redwolfprojectsinf.wixsite.com/redwolfprojects"
    // }
  ]

  return (
    <DivWrapper>
      <Typography variant='h1'>
        Check out some of our other projects
      </Typography>
      <hr style={{ marginBottom: 15 }} />
      <Grid container direction="row">
        <Grid item>
          <Grid container justifyContent={'space-between'}>
            {
              projects.map((p) => {
                return (
                  <Grid item xs={projects.length < 2 ? 8 : 4} key={p.siteName} >
                    <Project {...p} />
                  </Grid>

                )
              })
            }
          </Grid>
        </Grid>
      </Grid>
    </DivWrapper >
  )
}