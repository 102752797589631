import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const DivWrapper = styled('div')<{ component?: React.ElementType }>(
  ({ theme }) => ({
    backgroundColor: theme.palette.body.backgroundColor,
    color: theme.palette.body.color,
    textAlign: 'center',
    paddingTop: 20,
    paddingLeft: '1em',
    paddingRight: '1em'
}));

const StyledText = styled(Typography)<{ component?: React.ElementType }>({
  marginLeft: '2em',
  marginRight: '2em'
});

const StyledListItem = styled('li')<{ component?: React.ElementType }>({
  marginTop: '2%'
});

function About() {
  return (
    <div>
      <DivWrapper>
        <Typography variant='h1'>
          About Us
        </Typography>
        <hr />
        <StyledText variant='subtitle1'>
          Red Wolf Projects is a veteran owned and operated, small business.  After I got out of the Marine Corps, a coworker taught me the basics in programming. From there, I worked in tech support where I learned more about web application development. I worked my way to a software tester, and finally to a software engineer in 2019. My goal is to help small business owners and entrepreneurs with beautiful, responsive websites to help get their new businesses noticed by potential customers. I'm very passionate about making well designed websites for people to use and I'm always excited when software I build can help in someone else's success.
        </StyledText>
      </DivWrapper>
      <DivWrapper>
        <Typography variant='h2'>
          Our Technologies
        </Typography>
        <hr />
        <StyledText variant='subtitle1'>
          <ul>
            <StyledListItem>
              <strong>React Javascript:</strong> React is a Javascript library developed by Facebook for building user interfaces. React makes it easy to design and build responsive user interfaces with less coding and looks great on any device.
            </StyledListItem>
            <StyledListItem>
              <strong>Material Design:</strong> Material Design is a User Experience standard developed by experts to provide users with the best possible experience on your website or mobile application. We use the Material UI React Library of components to build our user interfaces to provide your users a pleasant, easy to use website or mobile application.
            </StyledListItem>
            <StyledListItem>
              <strong>Java with Spring Boot:</strong> Our server side code is written in Java with Spring Boot. Spring Boot has baked in a lot of features and functionality which means less code needs to be written to accomplish more. This allows us to quickly write fast, performant APIs to read and write data to the database.
            </StyledListItem>
            <StyledListItem>
              <strong>MongoDB:</strong> MongoDB is a database that stores Documents in various collections. MongoDB is a performant database that works well with Spring Boot and is easy to read and write data to and from.
            </StyledListItem>
          </ul>
          <Typography variant='subtitle1'>
            If you have any additional questions about our technologies or would like a free consultation, please email us using the email icon below.
          </Typography>
        </StyledText>
      </DivWrapper>
    </div>
  )
}

export default About;