import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    type: 'light',
    common: {
      gray: '#9e9e9e'
    },
    primary: {
      main: '#34515e'
    },
    secondary: {
      main: '#d32f2f'
    },
    body: {
      color: '#000000',
      white: '#ffffff',
      backgroundColor: '#fff',
      btn: '#f44336',
      hov: '#8eacbb'
    },
  },
  breakpoints: {
    values: {
      xs: 600,
      sm: 967,
      md: 1100
    }
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#34515e'
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        track: {
          backgroundColor: 'black'
        }
      }
    }
  },
  typography: {
    tab: {
      fontFamily: "Roboto, Helvetica, Arial, serif",
      textTransform: 'none',
      fontWeight: '700',
      fontSize: '1rem',
    },
    h1: {
      fontSize: '3em',
      marginBottom: '2%',
      textAlign: 'center'
    },
    btn: {
      fontFamily: "Roboto, Helvetica, Arial, serif",
      fontSize: '1rem',
      textTransform: 'none',
      color: '#fff'
    },
    h2: {
      fontSize: '2.25em',
      marginBottom: '2%',
      marginTop: '2%',
      textAlign: 'center'
    }
  },
  button: {
    backgroundColor: '#23fd4a',
    contained: {
      backgroundColor: '#424242'
    }
  }
});

export const DarkTheme = createTheme({
  palette: {
    mode: "dark",
    type: 'dark',
    primary: {
      main: '#34515e'
    },
    secondary: {
      main: '#b71c1c'
    },
    body: {
      color: '#ffffff',
      white: '#ffffff',
      backgroundColor: '#424242',
      btn: '#f44336',
      hov: '#8eacbb'
    },
  },
  breakpoints: {
    values: {
      xs: 600,
      sm: 967,
      md: 1100
    }
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#34515e'
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        track: {
          backgroundColor: '#fff!important'
        },
        thumb: {
          color: '#fff'
        }
      }
    }
  },
  typography: {
    tab: {
      fontFamily: "Roboto, Helvetica, Arial, serif",
      textTransform: 'none',
      fontWeight: '700',
      fontSize: '1rem',
    },
    h1: {
      fontSize: '3em',
      marginBottom: '2%',
      textAlign: 'center'
    },
    btn: {
      fontFamily: "Roboto, Helvetica, Arial, serif",
      fontSize: '1rem',
      textTransform: 'none',
      color: '#fff'
    },
    h2: {
      fontSize: '2.25em',
      marginBottom: '2%',
      marginTop: '2%',
      textAlign: 'center'
    }
  },
  button: {
    backgroundColor: '#23fd4a',
    contained: {
      backgroundColor: '#776fe6'
    }
  }
});

export const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#b71c1c'
    },
    secondary: {
      main: '#234432'
    }
  },
  breakpoints: {
    values: {
      xs: 600,
      sm: 967,
      md: 1100
    }
  },
  body: {
    color: '#000000'
  },
  typography: {
    tab: {
      fontFamily: 'Roboto',
      textTransform: 'none',
      fontWeight: '700',
      fontSize: '1rem',
    },
    btn: {
      fontFamily: 'Roboto',
      fontSize: '1rem',
      textTransform: 'none',
      color: '#fff'
    }
  },
  button: {
    backgroundColor: '#23fd4a',
    contained: {
      backgroundColor: '#776fe6'
    }
  }
});

